import * as React from "react"

import Layout from "@components/layout/layout"
import TextHero from "@components/textHero"
import { internalRoutes } from "@data/routes"

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <TextHero
      title="404: Page not found"
      subTitle="We couldn't find the page you were looking for."
      cta={{
        text: "Return to homepage",
        url: internalRoutes.HOME,
      }}
    />
  </Layout>
)

export default NotFoundPage
