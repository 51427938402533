import * as React from "react"

import Container from "@components/elements/container"
import Cta, { CtaProps } from "@components/elements/cta"

import styles from "./textHero.module.scss"

interface TextHeroProps {
  title: string
  subTitle?: string
  cta?: CtaProps
}

const TextHero = ({ title, subTitle, cta }: TextHeroProps) => (
  <section className={styles.textHero}>
    <Container className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      <Cta {...cta} className={styles.buttonCta} />
    </Container>
  </section>
)

export default TextHero
